import { render, staticRenderFns } from "./startpingu.vue?vue&type=template&id=147767fb&scoped=true&"
import script from "./startpingu.vue?vue&type=script&lang=js&"
export * from "./startpingu.vue?vue&type=script&lang=js&"
import style0 from "./startpingu.vue?vue&type=style&index=0&id=147767fb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147767fb",
  null
  
)

export default component.exports