<template>
  <div class="body">
    <van-nav-bar left-arrow left-text="返回" @click-left="onClickLeft"></van-nav-bar>
    <div class="weidubox">
      <div class="text">请选择维度</div>
      <div class="weiduitem" @click="scoremodle(item, index)" v-for="(item, index) in weidulist" :key="item.id">
        <div class="bg24" :style="{ height: (item.rec_num / item.topic_num) * 100 + '%' }" :class="{not: item.status != 1}"></div>
        <span class="itemicon iconfont" :class="iconlist[index]"></span>
        <p class="itemname">{{ item.main_name }}</p>
        <p class="state" v-if="item.status != 0 && item.sys_status != 1">
          <!-- <span v-if="item.status == 0">未评估</span> -->
          <span v-if="item.status == 1">已评估</span>
          <span v-else-if="item.status == 2">评估中</span>
        </p>
      </div>
    </div>
    <div class="onbtn" @click="onreport" :class="{over: over}">完成评估</div>
    <van-popup class="scorebox" v-model="show">
      <div class="calculatescore" v-for="item in scorearr" :key="item.id">
        <p @click="backSee(item.topic_sort, item.toprecord_id)">{{item.name}}</p>
        <ul>
          <li :class="{ bgcolor: item.score === 0 || item.score === '0.0' }">0分</li>
          <li :class="{ bgcolor: item.score == 0.5 }">0.5分</li>
          <li :class="{ bgcolor: item.score == 1 }">1分</li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Notify, Toast, Dialog } from "vant";
import {
  settopicid,
  getuid,
  getreviewid,
  gettopicid,
  setltemname,
  settopic_num,
  setlesson_id,
  setnext,
} from "/src/api/userdata.js";
import { finish, rightscore, topicRecord, nextTopic } from "../../../api/index";
export default {
  data() {
    return {
      show: false,
      bgcolor: "bgcolor",
      weidulist: [],
      scorearr: [],
      over: false,
      iconlist: [
        "icon-motivation",
        "icon-attention",
        "icon-obedience",
        "icon-a-imitativeability",
        "icon-voice",
        "icon-a-grossaction",
        "icon-thinking",
        "icon-fine",
        "icon-a-Namingability",
        "icon-a-LanguageExchange",
        "icon-a-gameskills",
        "icon-a-academicskills",
        "icon-imagination",
        "icon-a-mentalcapacity",
        "icon-Life",
        "icon-a-collectiveskills",
        "icon-a-socialskills",
        "icon-motivation",
        "icon-attention",
        "icon-obedience",
        "icon-a-imitativeability",
        "icon-voice",
        "icon-a-grossaction",
        "icon-thinking",
        "icon-fine",
        "icon-a-Namingability",
        "icon-a-LanguageExchange",
        "icon-a-gameskills",
        "icon-a-academicskills",
        "icon-imagination",
        "icon-a-mentalcapacity",
        "icon-Life",
        "icon-a-collectiveskills",
        "icon-a-socialskills",
      ],
    };
  },
  created() {
    this.getlist().then(() => {
      const uid = getuid();
      const id = getreviewid();
      let arr = [] // 本次不需要评估且还未处理的维度
      this.weidulist.map(e => {
        if (e.status == 0 && e.rec_num >= e.topic_num && e.sys_status != 1) {
          arr.push({
            lesson_id: e.lesson_id,
            manor_id: e.manor_id,
            main_id: e.main_id,
          })
        }
      })
      if (!this.over && arr.length > 0) {
        this.weiduHandle(uid, id, arr)
      }
    });
  },
  methods: {
    async getlist() {
      const uid = getuid();
      const id = getreviewid();
      let pathArr = ['must-topic/main-list', 'must-topic/skill-list', 'must-topic/feel-list', 'must-topic/rim-list']
      let params = {
        uid,
        review_id: id
      }
      const res = await this.$http.get(pathArr[this.$route.query.eva_type], { params: params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.weidulist = res.data.list;
      this.over = res.data.is_ass == 2 ? true : false
    },
    // 处理本次不需要评估的维度
    async weiduHandle(uid, review_id, arr) {
      let params = {
        uid,
        review_id,
        ass_num: this.$route.query.ass_num,
        data: JSON.stringify(arr)
      }
      // return
      const res = await this.$http.post('must-topic/sys-finish', params);
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.getlist()
    },
    scoremodle(item, index) {
      // setreviewid(item.id);
      settopicid(item.main_id);
      setltemname(item.main_name);
      settopic_num(item.topic_num);
      setlesson_id(item.lesson_id)
      setnext(index);
      const uid = getuid();
      const reviewid = getreviewid();
      const mainid = gettopicid();
      if (this.weidulist[index].status == 1) {
        rightscore(uid, reviewid, mainid).then((res) => {
          this.scorearr = res.data;
          this.show = true;
        });
      } else {
        let params = {
          uid: uid,
          review_id: reviewid,
          main_id: mainid,
          topic_sort: 0
        }
        nextTopic(params).then((res) => {
          if (res.code == "9998") {
            Dialog.alert({
              title: "提示",
              message: res.msg,
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              this.getlist()
            });
          }
          if (res.code * 1 !== 1 && res.code * 1 !== 9998) {
            return Notify(res.msg);
          }
          if (res.data.topic_type == 1) {
            this.$router.push({
              name: "addtable",
              query: {
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
                is_rec: res.data.is_rec,
                trec_id: res.data.trec_id,
                topic_id: res.data.topic_id,
                topic_sort: res.data.is_rec ? res.data.topic_sort : '',
              }
            });
          } else if (res.data.topic_type == 2) {
            this.$router.push({
              name: "choosepicture",
              query: {
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
                is_rec: res.data.is_rec,
                trec_id: res.data.trec_id,
                topic_id: res.data.topic_id,
                topic_sort: res.data.is_rec ? res.data.topic_sort : '',
              }
            });
            // 路由跳转到练习题
          } else if (res.data.topic_type == 3) {
            this.$router.push({
              name: "addTruefalse",
              query: {
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
                is_rec: res.data.is_rec,
                trec_id: res.data.trec_id,
                topic_id: res.data.topic_id,
                topic_sort: res.data.is_rec ? res.data.topic_sort : '',
              }
            });
          }
        });
      }
    },
    onClickLeft() {
      // this.$router.push({
      //   name: "AssessmentRecords",
      // });
      this.$router.go(-1)
    },
    // 回看
    backSee(topic_sort, toprecord_id) {
      if (toprecord_id != 0) {
        let uid = getuid();
        let reviewid = getreviewid();
        let mainid = gettopicid();
        topicRecord(uid, topic_sort, reviewid, mainid, 0).then((res) => {
          if (res.code != 1) {
            return Notify({ type: 'danger', message: res.msg });
          }
          if (res.data.topic_type === "1") {
            this.$router.push({
              name: "addtable",
              query: {
                topic_sort: topic_sort,
                not: 1,
                type: 0,
                eva_type: this.$route.query.eva_type,
              },
            });
          } else if (res.data.topic_type === "2") {
            this.$router.push({
              name: "choosepicture",
              query: {
                topic_sort: topic_sort,
                not: 1,
                type: 0,
                eva_type: this.$route.query.eva_type,
              },
            });
          } else if (res.data.topic_type === "3") {
            this.$router.push({
              name: "addTruefalse",
              query: {
                topic_sort: topic_sort,
                not: 1,
                type: 0,
                eva_type: this.$route.query.eva_type,
              },
            });
          }
        });
      }
    },
    onreport() {
      if (this.over === true) {
        // 完成所有维度评估
        // 1. 请求接口设置此学员为已评估状态
        // 2. 给出提示“本次评估已完成”
        // 3. 跳转到学员列表页面
        let uid = getuid();
        let review_id = getreviewid();
        let data = {
          uid,
          review_id,
          eva_type: this.$route.query.eva_type
        }
        finish(data).then((res) => {
          if (res.code == 1) {
            Toast.success({
              duration: 3000,
              forbidClick: true,
              message: "本次评估已完成",
              onClose: () => {
                this.$router.go(-1)
                // this.$router.replace({
                //   name: "AssessmentRecords",
                // });
              },
            });
          } else {
            Notify(res.msg)
          }
        })
      } else {
        Toast.fail("评估未完成")
      }
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  min-height: 100vh;
  background: url(../src/img/bj.png) top left/100% auto no-repeat;
  button {
    border: none;
  }
  .van-nav-bar {
    background: none;
    &::after {
      border: none;
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      .van-nav-bar__text {
        color: #666;
      }
      .van-icon {
        font-size: 0.14rem;
        color: #666;
        margin: 0;
      }
    }
  }
  .weidubox {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.27rem;
    align-items: center;
    .text {
      width: 1.44rem;
      height: 0.9rem;
      text-align: center;
      line-height: 0.9rem;
      font-size: 0.22rem;
      color: #333;
      margin: 0.08rem 0.17rem;
    }
    .weiduitem {
      width: 1.44rem;
      height: 0.9rem;
      background: #fffcf6;
      border-radius: 0.14rem;
      // border: 1px solid #FFEBB7;
      margin: 0.08rem 0.17rem;
      box-sizing: border-box;
      padding: 0.16rem 0.1rem;
      color: #2b1a00;
      position: relative;
      overflow: hidden;
      .itemicon {
        font-size: 0.28rem;
        position: relative;
      }
      .itemname {
        font-size: 0.17rem;
        margin: 0;
        position: relative;
      }
      .bg24 {
        width: 100%;
        background: #f1b207;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .not {
        background: #ffdf88;
      }
      .state {
        width: 0.9rem;
        height: 0.3rem;
        line-height: 0.4rem;
        box-sizing: border-box;
        color: #fff;
        background: #f14947;
        font-size: 0.1rem;
        transform: rotate(40deg);
        -ms-transform: rotate(40deg); /* IE 9 */
        -webkit-transform: rotate(40deg); /* Safari and Chrome */
        position: absolute;
        right: -0.22rem;
        top: -0.17rem;
        padding-left: 0.38rem;
      }
    }
  }
  .onbtn {
    width: 0.95rem;
    height: 0.34rem;
    background: #efefef;
    border-radius: 0.06rem;
    margin: 0.4rem auto;
    text-align: center;
    font-size: 0.14rem;
    color: #333333;
    line-height: 0.34rem;
  }
  .onbtn.over {
    background: #f14947;
    color: #fff;
  }
  .scorebox {
    height: 4.6rem;
    background: #ffffff;
    border-radius: 0.16rem;
    padding: 0.24rem 0.24rem 0.08rem;
  }
  .calculatescore {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.16rem;
    p {
      color: #f14947;
      font-size: 0.16rem;
      margin: 0;
      min-width: 0.38rem;
    }
    ul {
      display: flex;
      li {
        width: 0.64rem;
        height: 0.34rem;
        background: #ffffff;
        border-radius: 0.17rem;
        border: 1px solid #cccccc;
        font-size: 0.15rem;
        color: #666666;
        text-align: center;
        line-height: 0.34rem;
        margin-left: 0.2rem;
      }
      li.bgcolor {
        background: #f1b207;
        color: #fff;
        border: 1px solid #f1b207;
      }
    }
  }
}
@media only screen and (min-width: 769px) {
  .body {
    .weidubox {
      padding: 0 0.2rem;
      .text,
      .weiduitem {
        margin: 0.1rem;
      }
    }
  }
}
@media only screen and (min-width: 820px) {
  .body {
    .weidubox {
      padding: 0 0.15rem;
      .text,
      .weiduitem {
        margin: 0.07rem;
      }
    }
  }
}

@media only screen and (min-width: 912px) {
  .body {
    .weidubox {
      padding: 0 0.2rem;
      .text,
      .weiduitem {
        margin: 0.15rem;
      }
    }
  }
}
@media only screen and (min-width: 1024px) {
  .body {
    .weidubox {
      padding: 0 0.2rem;
      .text,
      .weiduitem {
        margin: 0.1rem;
      }
    }
  }
}
@media only screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2.5),
  (min-device-pixel-ratio: 2.5) {
  .body {
    .weidubox {
      padding: 0 0.2rem;
      .text,
      .weiduitem {
        margin: 0.08rem 0.16rem;
      }
    }
  }
}
</style>
